@import "minima";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.10.5/font/bootstrap-icons.min.css");

/*------CUSTOM site-wide Navigation and Footer CSS STARTS HERE----------*/
/* --- Accessibiity focus ---- */
:focus {
  outline: auto 2px Highlight!important;
  outline: auto 5px -webkit-focus-ring-color!important;
}

/* Reset */
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* Super Nav Menu */
#supernav li a{
  color:white;
  font-weight: 200;
}
#supernav li:nth-child(-n+3)::after{
  content: '/';
  color: white;
  padding:0.35rem;
  }
  
#site-logo{
  min-width: 220px;
  max-width: 400px;
}

/*----Google website search-----*/
/* Google Search inbox */
td.gsc-input ,
td.gsc-search-button  {
  border: 0px !important;
  padding: 0 !important;
}
.gsib_a{
  padding:0px !important;

}

td.gsib_b {
  display: none;
}

div.ad, div.adStd {
  display: none;
}

div.gsc-adBlock{
  display:none !important
  }

form.gsc-search-box {
  font-size: 22px !important;
}
table.gsc-input{
  margin-bottom: 0!important;
}
.gsc-selected-option-container{
  max-width: 100% !important;
}

/* to kill ads */
.gsc-control-cse {
  background-color: none;
  border: none !important;
  background-color: rgba(255, 255, 255, 0) !important;
  padding: 0 1em !important;
}

div.gsc-input-box {
  border: none;
  padding: 0;
  margin: 0;
}

#googleSCHLsearch {
  background-color: $red;
  color: white;
}

#gSearch{
  width: 100%; 
  min-width: 180px; 
  background-color: $red;
  border: 0px!important;
}

.gsc-input input[name="search"]{
  height: 2em !important;
  outline: 1px solid white !important;
  outline-offset: 2px !important;
}

button.gsc-search-button, .gsc-search-button-v2 {
  background-color: $red !important;
  margin-left: 0.5rem !important;
  border-color: $red !important;
  padding: 0px 8px !important; 
  outline: 1px solid white !important;
  outline-offset: 2px !important;
:hover{
  background-color: $red !important;
}
}

/* Diagonal stripes on navigation background */
.module {
  background: white;
  position: absolute;
  z-index: 999;
  width:100%;
}

.module-divid {
  height: 40px;
  background: white;
}

.header-stripe{
    background: rgb(173,16,42) !important;
    background: -moz-linear-gradient(45deg, rgba(173,16,42,1) 56%, rgba(196,18,48,1) 72%) !important;
    background: -webkit-linear-gradient(45deg, rgba(173,16,42,1) 56%, rgba(196,18,48,1) 72%) !important;
    background: linear-gradient(45deg, rgba(173,16,42,1) 56%, rgba(196,18,48,1) 72%) !important;
}
.stripe-1 {
  // background: repeating-linear-gradient(-30deg, #C03C47, #C03C47 2px, #B61F2E 1px, #B61F2E 7px);
  background-color: $red;
}

.stripe-divid {
  background: repeating-linear-gradient(-45deg, white, white 2px, #B61F2E 1px, #B61F2E 7px);
  opacity: 0.2;
}

/* Main Nav Menu */

#cornerImgAlumni {
  background-image: url("../../images/CornerImages/AlumniHallCornerImg.png");
  background-position: left bottom !important;
}

#cornerImgKing {
  background-image: url("../../images/CornerImages/KingLibraryCornerImg.png");
}

#cornerImgArt {
  background-image: url("../../images/CornerImages/LibraryCornerImg.png");
}

#main3nav{
  max-width: 1120px;
}

#main3nav .dropdown-menu{
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: right bottom;
  border-bottom: $red 1px solid;
  box-shadow: 0 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.2);
  @media screen and ( min-width:576px ){
    width:98%;
    left: auto;
    right: 1%;
  }
  & > div{
    margin-bottom: 1.25rem;;
  }
}

// Responsive toggler
.navbar-light .navbar-toggler{
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}

.navbar-toggler{
  background-color: white !important;
}

// All the clickable links in dropdown
.dropdown-menu li a{
  line-height: 1.35em;
  margin-bottom: 0.75em;
  display: block;
  color: #333;
  white-space: normal;
  margin-left: -0.5em;
  padding: 0 0.25em 0 0.5em;
  &:hover {
    text-decoration: none;
    color: white;  
    background: $red;
    padding-right: 5px;
    background: linear-gradient(90deg, rgba(195,20,45,1) 45%, rgba(255,255,255,.1) 80%);
  }
}

/*---Footer Style---*/
.footer-container {
  min-width: 850px;
}

.footer {
  font-family: 'Raleway', sans-serif;
  color: white;
}

.footerTitle {
  text-transform: uppercase;
}

.footer address a,
.footer p a {
  color: white;
  padding: 0 0.2rem;
  &:hover {
    text-decoration: none;
    color: #941728 !important; 
    background-color: white;
  }
}

.footer li {
  font-family: 'Raleway', sans-serif;
  color: white;
  list-style: none !important;
  margin-left:-1.825em;

  a {
    color: white;
    padding: 0 0.2rem;
    &:hover {
      text-decoration: none;
      color: #941728 !important; 
      background-color: white;
    }
  }
}

h2.footer-header {
  @include font-size(1em!important);
}

li.title{
  line-height: 1.5em;
  margin: 1em 0;
}

/* -- icon to signify it opens in new window -- */
// a[target="_blank"]:after {
//   content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==");
//   margin: 0 3px 0 5px;
// }
